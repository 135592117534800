import React from 'react';
import styles from './IntegrationContentInstruction.module.scss';

interface IntegrationContentInstructionProps {
  children: React.ElementType;
}

const IntegrationContentInstruction: React.SFC<
  IntegrationContentInstructionProps
> = ({ children }) => (
  <div className={styles.instruction}>
    <h4 className={styles.title}>How to use</h4>
    {children}
  </div>
);

export default IntegrationContentInstruction;
