import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import Card from '../ui/Card';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './IntegrationCard.module.scss';

interface IntegrationCardProps {
  slug?: string;
  name: string;
  logo: string;
  premium?: boolean;
  coming?: boolean;
  html?: string;
}

const IntegrationCard: React.SFC<IntegrationCardProps> = ({
  slug,
  name,
  logo,
  premium,
  coming,
  html,
}) => {
  const card = (
    <Card hoverColor={html ? 'blue' : undefined}>
      <div className={styles.container}>
        <Spacing all={2}>
          <div className={styles.titleRow}>
            <div>
              <Text bold darker>
                {name}
              </Text>
            </div>
            {premium && (
              <div className={styles.tagContainer}>
                <span className={`${styles.tag} ${styles.premium}`}>
                  premium
                </span>
              </div>
            )}
            {coming && (
              <div className={styles.tagContainer}>
                <span className={`${styles.tag} ${styles.coming}`}>
                  coming soon
                </span>
              </div>
            )}
          </div>
          <div className={styles.logoContainer}>
            <div
              style={{ backgroundImage: `url(${logo})` }}
              className={classNames(styles.logo, { [styles.coming]: coming })}
            />
          </div>
        </Spacing>
      </div>
    </Card>
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return html ? <Link to={`/integrations/${slug}/`}>{card}</Link> : card;
};

export default IntegrationCard;
