import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import RehypeReact from 'rehype-react';
import Button from '../ui/Button';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import styles from './IntegrationContent.module.scss';
import IntegrationContentInstruction from './IntegrationContentInstruction';

interface Header {
  id: string;
  text: string;
}

interface IntegrationContentProps {
  htmlAst: object;
  name: string;
  setupUrl?: string;
  installSlug?: string;
}

const renderMarkdown = new RehypeReact({
  createElement: React.createElement,
  components: { 'slab-instruction': IntegrationContentInstruction },
}).Compiler;

const IntegrationContent: React.SFC<IntegrationContentProps> = ({
  htmlAst,
  name,
  setupUrl,
  installSlug,
}) => {
  const [contentNode, setContentNode] = useState<HTMLElement | null>();
  const [headers, setHeaders] = useState<Header[]>([]);
  const [activeId, setActiveId] = useState<string>();
  const headersRef = useRef<Header[]>([]);
  const intersectionsByHeaderIdRef = useRef<{ [id: string]: boolean }>({});

  useEffect(() => {
    if (contentNode) {
      const headerElements = Array.from(contentNode.getElementsByTagName('h3'));
      const newHeaders = headerElements.map((header) => ({
        id: header.id,
        text: header.innerText,
      }));
      setHeaders(newHeaders);
      headersRef.current = newHeaders;

      if (typeof IntersectionObserver === 'undefined') {
        return undefined;
      }

      const observer = new IntersectionObserver(
        ([entry]) => {
          intersectionsByHeaderIdRef.current[entry.target.id] =
            entry.intersectionRatio === 1;

          // If multiple headers match, make the first one active.
          // Note that we can't use the headers state, since it's out-of-date
          // from within this callback.
          // eslint-disable-next-line no-restricted-syntax
          for (const header of headersRef.current) {
            if (intersectionsByHeaderIdRef.current[header.id]) {
              setActiveId(header.id);
              break;
            }
          }
        },
        { threshold: 1 },
      );

      headerElements.forEach((el) => observer.observe(el));

      return () => observer.disconnect();
    }

    return undefined;
  }, [contentNode]);

  const isShowingSidebar = installSlug || (setupUrl && setupUrl.length > 0);

  return (
    <PageSection bordered>
      <Spacing vertical={9}>
        <div
          className={classNames(styles.container, {
            [styles.withSidebar]: isShowingSidebar,
          })}
        >
          {isShowingSidebar && (
            <div className={styles.sidebar}>
              {headers.length > 1 && (
                <ul className={styles.toc}>
                  {headers.map(({ id, text }) => (
                    <li key={id}>
                      <a
                        className={activeId === id ? styles.active : undefined}
                        href={`#${id}`}
                      >
                        {text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              {installSlug && (
                <div className={styles.actionButton}>
                  <Button
                    small
                    block
                    target="_blank"
                    to={`/app/admin/integrations/${installSlug}`}
                    track={{ label: 'integration-install', platform: name }}
                  >
                    Install {name}
                  </Button>
                </div>
              )}
              {setupUrl && setupUrl.length > 0 && (
                <div className={styles.actionButton}>
                  <Button small block secondary to={setupUrl}>
                    View Documentation
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className={styles.main}>
            <h2>Features</h2>
            <div ref={setContentNode} className={styles.content}>
              {renderMarkdown(htmlAst)}
            </div>
            {(!!installSlug || setupUrl != null) && (
              <>
                <h2 className={styles.installHeader}>
                  {installSlug ? 'Install' : 'Learn More'}
                </h2>
                <p className={styles.installInstruction}>
                  {[
                    installSlug &&
                      'Some features of this integration require admin installation.',
                    setupUrl &&
                      'For more details on setting up the integration, check out our Help Center article, or get in touch with our support team!',
                  ]
                    .filter((sentence) => !!sentence)
                    .join(' ')}
                  <br />
                  <br />
                  Questions?{' '}
                  <a href="mailto:support@slab.com">support@slab.com</a>
                </p>
                <div key="actionButtons" className={styles.actionButtons}>
                  {installSlug && (
                    <div className={styles.actionButton}>
                      <Button
                        block
                        target="_blank"
                        to={`/app/admin/integrations/${installSlug}`}
                        track={{
                          label: 'integration-install',
                          platform: name,
                        }}
                      >
                        Install {name}
                      </Button>
                    </div>
                  )}
                  {setupUrl && setupUrl.length > 0 && (
                    <div className={styles.actionButton}>
                      <Button block secondary to={setupUrl}>
                        View Documentation
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Spacing>
    </PageSection>
  );
};

export default IntegrationContent;
