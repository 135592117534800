import React from 'react';
import slabIcon from '../../images/mark.svg';
import PageHeader from '../layout/PageHeader';
import Spacing from '../ui/Spacing';
import styles from './IntegrationHeader.module.scss';

const DEFAULT_DESCRIPTION = 'Designed to keep your team on the same page.';

interface IntegrationHeaderProps {
  icon?: string;
  name: string;
  description?: string;
}

const IntegrationHeader: React.SFC<IntegrationHeaderProps> = ({
  icon,
  name,
  description,
}) => (
  <Spacing bottom={10}>
    <PageHeader
      top={
        icon && (
          <div className={styles.iconContainer}>
            <img alt="Slab" className={styles.icon} src={slabIcon} />
            <div className={styles.plus}>+</div>
            <img alt={name} className={styles.icon} src={icon} />
          </div>
        )
      }
      title={`Slab & ${name}`}
      description={description || DEFAULT_DESCRIPTION}
    />
  </Spacing>
);

export default IntegrationHeader;
