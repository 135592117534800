import { graphql } from 'gatsby';
import React from 'react';
import IntegrationContent from '../components/integration/IntegrationContent';
import IntegrationHeader from '../components/integration/IntegrationHeader';
import IntegrationsContent from '../components/integrations/IntegrationsContent';
import MarketingLayout from '../components/layout/MarketingLayout';
import PageSection from '../components/ui/PageSection';
import Spacing from '../components/ui/Spacing';
import { IntegrationsPageData } from '../pages/integrations';
import { IntegrationMarkdownRemark } from '../types';
import allIntegrations from '../utils/allIntegrations';

interface IntegrationTemplateData extends IntegrationsPageData {
  markdown: IntegrationMarkdownRemark;
  icon?: {
    publicURL: string;
  };
}

interface IntegrationTemplateProps {
  data: IntegrationTemplateData;
}

const IntegrationTemplate: React.SFC<IntegrationTemplateProps> = (props) => {
  const {
    markdown: {
      htmlAst,
      frontmatter: { name, setup, install, similar, description },
    },
    icon,
  } = props.data;

  const similarServiceSlugs = similar ? similar.split(/,\s*/) : [];
  const similarServices = allIntegrations(props.data).filter(
    ({ slug }) => slug && similarServiceSlugs.includes(slug),
  );

  return (
    <MarketingLayout noFooterPadding title={`${name} Integration`}>
      <IntegrationHeader
        icon={icon?.publicURL}
        name={name}
        description={description}
      />
      <IntegrationContent
        htmlAst={htmlAst}
        name={name}
        setupUrl={setup}
        installSlug={install}
      />
      {similarServices.length > 0 && (
        <Spacing vertical={10}>
          <PageSection>
            <Spacing bottom={2}>
              <h4>Similar Services</h4>
            </Spacing>
          </PageSection>
          <IntegrationsContent title={null} services={similarServices} />
        </Spacing>
      )}
    </MarketingLayout>
  );
};

export default IntegrationTemplate;

export const query = graphql`
  query ($slug: String!) {
    markdown: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        name
        description
        setup
        install
        similar
      }
    }
    icon: file(
      relativeDirectory: { eq: $slug }
      sourceInstanceName: { eq: "integrations" }
      name: { eq: "icon" }
    ) {
      publicURL
    }

    ...IntegrationsQueryFragment
  }
`;
